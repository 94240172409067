import React from 'react'

const Contact = () => {
    return (
        <>
          <section className="contact" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 mb-60 text-center text-white">
              <h3 className="section-title">Contact Us</h3>
              <p>We would love to hear from you! For orders, inquiries, or more information, <br/>please get in touch with us:</p>
            </div>
          </div>  
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="footer-contact">
                <span className="icon-navigation"></span>
                <strong>Office Address:</strong><br />
                Raiwind, Lahore, Pakistan
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="footer-contact">
                <span className="icon-call"></span>
                <strong>Phone:</strong><br />
                +923103131333
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-contact">
                <span className="icon-mail"></span>
                <strong>Email:</strong><br />
                info@tayyabat.pk
              </div>
            </div>
          </div>
        </div>
      </section>
        <footer className="footer text-white text-center py-3">
     
        <p>© {new Date().getFullYear()} Tayyibaat Foods Pvt Ltd. All rights reserved.</p>    </footer>
        </>
    
      
    )
}

export default Contact