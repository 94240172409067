import React from 'react'
import logo from '../assets/images/logo.svg'


const Navbar = () => {
    return (
      <header className="navbar navbar-expand navbar-light bg-light fixed-top">
        <div className="container">
          <a className="navbar-brand" href="/">
           <img src={logo} alt="Logo" />         
            </a>
       
          <div className="navbar-menu" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#welcome">Tayyibat</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#mission">Our Mission</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#products">Our Products</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#why-choose-us">Why Us?</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contact</a>
              </li>
              <li className="nav-item call">
                <a href="tel: +923103131333">
                  <svg id="a" width="20px" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.33 61.42">
                    <path d="M42.08,61.42c-4.51-.01-8.52-1.52-12.31-3.9-6.62-4.16-11.99-9.64-16.92-15.62-3.97-4.81-7.46-9.93-10.05-15.63C1.28,22.91.1,19.44,0,15.71-.12,10.84,1.31,6.51,5.07,3.22,8.08.58,11.73-.25,15.65.06c2.04.16,3.06,1.61,3.59,3.38.58,1.92,1.01,3.88,1.43,5.84.44,2.03.82,4.07,1.17,6.12.34,1.97-.08,2.83-1.85,3.76-1.09.57-2.26,1-3.38,1.53-1.43.67-1.58,1.02-1.27,2.57.44,2.19,1.54,4.1,2.57,6.03,3.01,5.61,7.26,10.1,12.51,13.67.87.59,1.72,1.21,2.65,1.68,1.23.63,1.95.43,2.82-.66.42-.53.7-1.17,1.12-1.69.71-.88,1.45-1.76,2.26-2.55.52-.51,1.23-.61,1.97-.38,4.32,1.38,8.5,3.12,12.51,5.26,2.45,1.3,2.91,2.15,2.39,4.88-.97,5.16-3.77,8.98-8.7,11.06-1.7.72-3.53.88-5.36.86Z" fill="currentColor" />
                  </svg> +923103131333
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    )
}

export default Navbar