import React from 'react'

const Choose = () => {
    return (
      <section className="py-100 choose" id="why-choose-us">
        <div className="container">
          <h3 className="section-title">Why Choose Us?</h3>
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="text-center">
                <span className="icon-medal-2"></span>
                <h4>Quality Assurance</h4>
                <p>We ensure that all our products meet international quality standards.</p>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="text-center">
                <span className="icon-milk-2"></span>
                <h4>Hygienic Packaging</h4>
                <p>Our products are packaged in state-of-the-art facilities to maintain hygiene and freshness.</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-center">
                <span className="icon-truck-2"></span>
                <h4>Home Delivery</h4>
                <p>Convenient home delivery service across Lahore, bringing the best of nature right to your doorstep.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Choose