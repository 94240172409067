import React from 'react';

const Mission = () => {
    return (
      <section className="py-90 mission" id="mission">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center">
              <div className="content-wrap mr-0 mr-md-5">
                <h3 className="section-title lead mb-40">Elevating Organic Excellence</h3>
                <p>
                  At Tayyibaat Foods Pvt Ltd, our mission is to enhance the well-being and satisfaction of our customers by providing premium, export-grade organic food items that are both nourishing and delightful. Nestled in the heart of Raiwind, Lahore, Pakistan, we pride ourselves on our dedication to quality and taste. Our commitment goes beyond just delivering food; we aim to bring an exceptional culinary experience directly to your doorstep.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="images/mission.jpg" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-md-6 mb-4">
              <div className="card h-100">
                <div className="card-body">
                  <div className="icon">
                    <span className="icon-medal-1"></span>
                  </div>
                  <h5 className="card-title">Commitment to Quality</h5>
                  <p className="card-text">We believe in nature's bounty. Our products are meticulously sourced and tested to ensure the highest standards of purity and excellence. We offer food that is healthy, flavorful, and satisfying.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card h-100">
                <div className="card-body">
                  <div className="icon">
                    <span className="icon-grape"></span>
                  </div>
                  <h5 className="card-title">Sustainable Practices</h5>
                  <p className="card-text">Sustainability is core to our operations. Through ethical farming and responsible sourcing, we ensure our products benefit both you and the planet, reflecting our dedication to future generations.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="card h-100">
                <div className="card-body">
                  <div className="icon">
                    <span className="icon-account-circle"></span>
                  </div>
                  <h5 className="card-title">Customer-Centric Approach</h5>
                  <p className="card-text">Your satisfaction is our priority. We aim to exceed expectations with exceptional service and a seamless shopping experience, building lasting relationships based on trust and transparency.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card h-100">
                <div className="card-body">
                  <div className="icon">
                    <span className="icon-tree-palm"></span>
                  </div>
                  <h5 className="card-title">Vision for the Future</h5>
                  <p className="card-text">We are excited to grow and innovate, expanding our product range and enhancing our processes. Our vision is to be a global symbol of quality and integrity in organic food production.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Mission;