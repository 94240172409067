    import React from 'react'

    const Hero = () => {
      return (
        <section id="welcome" className="hero">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h2 className="text-center mb-4">Experience Quality with Tayyibaat Foods Pvt Ltd</h2>
                <p className="text-center">At Tayyibaat Foods, we specialize in providing premium, export-grade organic food items. Situated in Raiwind, Lahore, Pakistan, we are dedicated to delivering unparalleled quality and taste to your home.</p>
              </div>
            </div>
          </div>
        </section>
      )
    }

    export default Hero