import React from 'react'

const Products = () => {
    return (
      <section className="products-section py-90" id="products">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-10 mb-50 ">
              <h3 className="section-title">Our Products</h3>
              <p>We offer a diverse range of products, all carefully selected and hygienically packaged to maintain their freshness and quality:</p>
            </div>
          </div>
          <div className="row mb-60">
            <div className="col-md-4 text-center mb-4 mb-md-0">
              <div className="product">
                <div className="product-view">
                  <img src="images/meat.png" alt="Meat" className="img-fluid mb-2" />
                </div>
                <div className="product-text">
                  <h5>Meat</h5>
                  <p>Fresh, high-quality cuts sourced from trusted suppliers.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center mb-4 mb-md-0">
              <div className="product">
                <div className="product-view">
                  <img src="images/fruites.png" alt="Fruits" className="img-fluid mb-2" />
                </div>
                <div className="product-text">
                  <h5>Fruits</h5>
                  <p>A variety of seasonal and exotic fruits.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center mb-4 mb-md-0">
              <div className="product">
                <div className="product-view">
                  <img src="images/vegies.png" alt="Vegetables" className="img-fluid mb-2" />
                </div>
                <div className="product-text">
                  <h5>Vegetables</h5>
                  <p>Crisp, farm-fresh vegetables.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-4 text-center mb-4 mb-md-0">
              <div className="product">
                <div className="product-view">
                  <img src="images/spices.png" alt="Herbs & Spices" className="img-fluid mb-2" />
                </div>
                <div className="product-text">
                  <h5>Herbs & Spices</h5>
                  <p>Aromatic herbs and spices to enhance your culinary creations.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center mb-4 mb-md-0">
              <div className="product">
                <div className="product-view">
                  <img src="images/pulses.png" alt="Pulses" className="img-fluid mb-2" />
                </div>
                <div className="product-text">
                  <h5>Pulses</h5>
                  <p>Nutrient-rich pulses for a healthy diet.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center mb-4 mb-md-0">
              <div className="product">
                <div className="product-view">
                  <img src="images/rice.png" alt="Rice & Grains" className="img-fluid mb-2" />
                </div>
                <div className="product-text">
                  <h5>Rice & Grains</h5>
                  <p>Premium rice and grains for your daily needs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Products