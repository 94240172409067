import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero'
import Mission from './components/Mission'
import Products from './components/Products'
import Choose from './components/Choose'
import Contact from './components/Contact';

function App() {
  return (
    <>
      <Helmet>
        <title>Tayyabat - Your Trusted Source for Quality Products</title>
        <meta name="description" content="Discover a wide range of high-quality products at Tayyabat. From innovative solutions to everyday essentials, we've got you covered." />
        <link rel="icon" href="/assets/images/favicon.png" />
      </Helmet>
      <Navbar />
      <Hero />
      <Mission />
      <Products />
      <Choose />
      <Contact />
    </>
  );
}

export default App;